footer {
    background-color: #111111;
    color: white;
    font-family: 'Outfit', sans-serif;
    padding: .5rem 0 1rem 0;
    text-align: center;
}

footer .footer__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer__container .contact__card {
    line-height: 1.5rem;
    margin: 0 auto;
    opacity: 0.8;
    padding: .5rem 0;
    text-align: center;
    width: 85%;
}

.footer__container .social__icons {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0.8;
}

.social__icons a .footer__icon:hover {
    color: #2586B4;
}

footer p {
    margin: 0;
    opacity: 0.8;
    padding: 5px 0;
}