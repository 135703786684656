.about {
    background-position: center center;
    background-size: cover;
    max-width: 100%;
}

.about .about__container {
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 7rem;
    width: 100%;
}

.about .about__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'Outfit', sans-serif;
    justify-content: center;
    margin: 0 auto;
    max-width: 90%;
    text-align: center;
}

.about__header h1 {
    color: white;
    font-size: 4rem;
    font-weight: 400;
    margin: 0 auto;
    padding-bottom: 1rem;
    width: 100%;
}

.about__header p {
    color: white;
    font-size: 1.6rem;
    font-weight: 200;
    line-height: 2.3rem;
}

.about .about__cards {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 89.6%;
    padding: 1rem 0 2rem 0;
}

@media screen and (max-width: 812px) {
    .about .about__cards {
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0;
    }
}

@media screen and (max-width: 440px) {
    .about__header h1 {
        font-size: 3rem;
    }

    .about__header p {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}