.contact__container {
    align-items: center;
    background-color: #015A83;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    padding: 7rem 0;
}

.contact__container .contact__left, .contact__right {
    width: 45%;
}

.contact__left {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    font-family: 'Outfit', sans-serif;
    justify-content: center;
    padding: 0 2rem;
}

.contact__left img {
    max-height: 225px;
    max-width: 225px;
}

.contact__left h3 {
    font-size: 2rem;
    margin: 0;
    padding: 1rem 0;
    text-align: center;
}

.contact__left .left__p {
    font-size: 1.3rem;
    margin: 0;
    padding-bottom: 1rem;
    text-align: center;
}

.contact__left .socials {
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: .5rem;
    padding: .5rem 1rem;
}

.contact__left .socials .social__item {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 5px;

    color: #666666
}

.contact__left .socials .social__item p {
    font-size: 1rem;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .contact__container {
        flex-direction: column;
        overflow: hidden;
    }

    .contact__container .contact__left {
        width: 90%;
    }

    .contact__left .socials {
        display: none;
    }

    .contact__container .contact__right {
        width: 90%;
    }
}