.home__header {
    background-color: rgba(0, 0, 0, 0.8);
    background-position: center center;
    background-size: cover;
    max-width: 100%;
}

.home__header .header__container {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: row;
    height: 93vh;
    padding-top: 3.3rem;
    width: 100%;
}

.header {
    align-items: center;
    color: #E7F7FA;
    display: flex;
    flex-direction: column;
    font-family: "Outfit", sans-serif;
    justify-content: space-evenly;
    padding-bottom: 2rem;
    width: 100%;
}

.fade-in {
    animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {
 opacity: 0;
}
    100% {
 opacity: 100%;
}
}

.header__logo {
    max-width: 300px;
}

.header h3 {
    font-size: 2.5rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    text-align: center;
    width: 90%;
    font-weight: 400;
}

.header .btn__container {
    display: flex;
    flex-direction: flex-start;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.header .btn__container .header__btn {
    background-color: transparent;
    border: 2px solid #E7F7FA;
    border-radius: 5px;
    color: #E7F7FA;
    font-size: 1.75rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding: 15px 20px;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.header .btn__container .header__btn:hover {
    box-shadow: inset 350px 0 0 0 #2586B4;
    cursor: pointer;
}

/* Styling for Middle Section */

.mid__home {
    align-items: center;
    background-color: #2586B4;
    color: #E7F7FA;
    display: flex;
    font-family: 'Outfit', sans-serif;
    justify-content: space-evenly;
    max-height: auto;
    max-width: 100%;
    padding: 5rem 3rem;
}

 .mid__home .mid__left {
    padding-right: 2rem;
    width: 45%;
}

.mid__home .mid__left h2 {
    color: #E7F7FA;
    font-family: 'Outfit', sans-serif;
    font-size: 3rem;
    line-height: 1.5em;
    font-weight: 400;
}

.mid__left p {
    font-size: 1.4rem;
    line-height: 1.5em;
}

.mid__beach {
    border: 2px solid #E7F7FA;
    border-radius: 5px;
    max-width: 50%;
}

.bottom__home {
    align-items: center;
    background-color: #015a83;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: hidden;
    padding: 8em 0;
    width: 100%;
}

.bottom__home .bottom__left {
    align-items: flex-end;
    color: #E7F7FA;
    display: flex;
    flex-direction: column;
    font-family: 'Outfit', sans-serif;
    justify-content: center;
    margin-left: 4rem;
    width: 40%;
}

.bottom__home .bottom__left h3 {
    font-size: 2.8rem;
    line-height: 3.5rem;
    text-align: right;
    width: 100%;
    font-weight: 400;
}

.bottom__home .bottom__left .bottom__btn {
    background-color: transparent;
    border: 2px solid #E7F7FA;
    border-radius: 5px;
    color:#E7F7FA;
    font-size: 1.7rem;
    padding: 1rem 4rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.bottom__home .bottom__left .bottom__btn:hover {
    box-shadow: inset 350px 0 0 0 white;
    color:#2586B4;
}
/* Review Section */

.review__section {
    background-color: #A9A9A9;
    display: flex;
    flex-direction: column;
    padding: 5rem 0;
}

.review__section h3 {
    color: white;
    font-family: 'Outfit', sans-serif;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.75rem;
    margin: 0;
    margin: 0 auto;
    padding-top: .5rem;
    text-align: center;
    width: 80%;
}

.review__section p {
    color: white;
    font-family: 'Outfit', sans-serif;
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0;
    padding: 1rem 0;
    text-align: center;
}

.end__section {
    background-color: black;
    color: white;
    font-family: 'Outfit', sans-serif;
    max-width: 100%;
    padding: 3rem 0;
}

.end__section .end__header {
    border-bottom: 4px solid #2586B4;
    font-size: 2.7em;
    font-weight: 400;
    margin: 0 auto;
    padding-bottom: 2rem;
    text-align: center;
    width: 85%;
}

.end__section .end__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: 100%;
    padding-top: 3rem;
}

.end__section .end__container .left__img {
         border: 3px solid white;
         border-radius: 5px;
         max-width: 500px;
     }

.end__section .end__container .right__end {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    width: 45%;
}

.right__end .icon__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.end__section .end__container .right__end h4 {
    font-size: 1.9em;
    font-weight: 300;
    line-height: 1.6em;
    text-align: center;
}

.end__section .end__container .right__end .contact__box {
    display: flex;
    justify-content: center;
    width: 100%;
}

.end__section .end__container .right__end .contact__box .phone {
    align-items: center;
    border: 2px solid white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    font-size: 1.6em;
    font-weight: 200;
    justify-content: baseline;
    margin-right: 10px;
    padding: 10px 15px;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.phone:hover {
    box-shadow: inset 300px 0 0 0 #2586B4;
}

.phone .phone__link {
    color: white;
    text-decoration: none;
    width: 100%;
}

.contact__box .email__btn {
    background-color: transparent;
    border: 2px solid white;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 1.6em;
    font-weight: 200;
    margin-left: 10px;
    padding: 10px 80px;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.email__btn:hover {
    box-shadow: inset 300px 0 0 0 #2586B4;
}

@media screen and (min-width: 964px) {
    .bottom__home .resize__header {
        display: none;
    }
}

@media screen and (max-width: 1180px) {
    .mid__home .mid__left h2 {
        font-size: 2rem;
    }
    .mid__left p {
        font-size: 1.2rem;
    }

    .end__section .end__container {
        flex-direction: column;
    }

    .end__section .end__container .right__end {
            padding-top: 2rem;
            width: 80%;
        }
}

@media screen and (max-width: 964px) {
    .bottom__home {
        flex-direction: column;
        padding: 4em 0;
    }
    .bottom__home .resize__header {
        color: white;
        font-family: 'Outfit', sans-serif;
        font-size: 2.6rem;
        margin: 0;
        padding-bottom: .5em;
        text-align: center;
        width: 85%;
    }
    .bottom__home .bottom__left {
        align-items: center;
    }
    .bottom__home .bottom__left h3 {
          display: none;
      }

    .end__section .end__container .left__end {
        max-width: 80%;
    }
}

@media screen and (max-width: 920px) {
    .mid__home {
        flex-direction: column-reverse;
    }

    .mid__home .mid__left {
        padding-right: 0;
        text-align: center;
        width: 100%;
    }

    .mid__home .mid__beach {
        max-width: 90%;
    }

    .bottom__home .resize__header {
        font-size: 2.5rem;
        font-weight: 400;
    }
    .bottom__home .bottom__left {
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }

    .end__section .end__header {
        font-size: 2.5rem;
    }

    .end__section .end__container .right__end h4 {
        font-size: 1.7rem;
    }

    .end__section .end__container .right__end .contact__box .phone {
        font-size: 1.2rem;
    }

    .contact__box .email__btn {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 681px) {
    .header .btn__container .header__btn {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 569px) {
    .home__header .header__container {
        padding-top: 1.5rem;
    }

    .home__header .header__container .header {
        padding-bottom: .5rem;
    }

    .home__header .header__container .header h3 {
        font-size: 2rem;
        margin: 1rem;
        padding-bottom: 1rem;
    }

    .home__header .header__container .header .btn__container {
        align-items: center;
        flex-direction: column-reverse;
        justify-content: center;
        margin: 0;
    }

    .home__header .header__container .header .btn__container .schedule {
        margin-top: 10px;
        padding: 15px 38px;
    }

    .mid__home .mid__left h2 {
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.2em;
    }

    .mid__home .mid__beach {
        max-width: 350px;
        object-fit:fill;
    } 

    .review__section {
        padding: 3rem 0;
    }

    .review__section h3 {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.8rem;
        width: 90%;
    }

    .review__section p {
        font-weight: 200;
    }

    .bottom__home .resize__header {
        font-size: 2rem;
        font-weight: 400;
        width: 95%;
    }
    .bottom__home .bottom__left {
        width: 95%;
    }

    .bottom__home .bottom__left .bottom__btn {
        font-size: 1.3rem;
        padding: .5rem 2rem;
    }

    .end__section .end__header {
        font-size: 2.2rem;
        font-weight: 400;
        width: 90%;
    }

    .end__section .end__container .right__end h4 {
        font-size: 1.2rem;
    }

    .end__section .end__container .left__img {
        max-width: 85%;
    }
    .end__section .end__container .right__end .contact__box {
        align-items: center;
        flex-direction: column;
    }

    .end__section .end__container .right__end .contact__box .phone {
        margin-right: 0;
        padding: 10px 34px;
    }

    .end__section .end__container .right__end .contact__box .email__btn {
        margin-left: 0;
        margin-top: 20px;
    }
}