nav {
    align-items: center;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
}

nav img {
    height: 60px;
    padding: 10px 10px 10px 20px;
    width: 60px;
}

nav .hamburger {
    display: none;
}

nav ul {
    display: flex;
    gap: 4rem;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav .links li .nav__item {
    color: #E7F7FA;
    font-family: 'Outfit', sans-serif;
    font-size: 1.4rem;
    padding-bottom: 2px;
    text-decoration: none;
}

 .nav__item {
    background-image: linear-gradient(#00a7fb 0 0);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;

     /*OR bottom left*/
    transition:
        background-size 0.3s,
        background-position 0s 0.3s;
}

.nav__item:hover {
    background-position: 100% 100%;
    background-size: 100% 2px;
     /*OR bottom right*/
}

.icon__link {
    color: white;
}

.facebook {
    padding-right: 4rem;
}

.icon__link:hover {
    color: #00a7fb;
}

.contact__btn {
    margin-right: 6rem;
    margin-top: .8rem;
}

.nav__button {
    background: none;
    border: none;
    color: #E7F7FA;
    font-family: 'Outfit', sans-serif;
    font-size: 1.3rem;
    margin: 0 auto;
    padding: .5rem 1rem;
    position:absolute;
    text-align: center;
    transform: translate(-50%, -50%);
}

.nav__button:before {
    background: #00a7fb;
    border-radius: 5px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transform-origin: bottom right;
    transition: transform 0.6s;
    width: 100%;
    z-index: -1;
}

.nav__button:hover:before {
    border-radius: 5px;
    transform: scale(1);
    transform-origin: top left;
    transition: transform 0.5s;
}

.nav__button:after {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 5px;
    border-radius: 5px;
    box-sizing: border - box;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1);
    transform-origin: top left;
    transition: transform 0.5s;
    width: 100%;
    z-index: -1;
}

.nav__button:hover:after {
    border-radius: 5px;
    transform: scale(0);
    transform-origin: bottom right;
    transition: transform 0.6s;
}

.navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 10px rgba(0);
}

@media screen and (max-width: 920px) {
    nav .hamburger {
        color: white;
        display: block;
        margin-right: 1.5rem;
    }

    nav ul {
        align-items: center;
        background-color: rgba(0, 0, 0, 0.9);
        display: none;
        flex-direction: column;
        left: 0;
        padding: 10px;
        position: absolute;
        top: 70px;
        width: 100%;
    }

    nav ul.is-open {
        align-items: center;
        display: flex;
        margin-top: 0;
        padding: 10px 0 20px 0;
    }

    ul .nav__item {
        gap: 0;
        margin: 0 auto;
        padding: 13px 0 13px 0;
        width: 100%;
    }
    ul .facebook {
        padding-right: 0;
    }
    ul .contact__btn {
        margin: 0;
        margin-bottom: 1rem;
    }
}