.service__page {
    align-items: center;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    font-family: 'Outfit', sans-serif;
    justify-content: flex-start;
    max-width: 100%;
    padding: 6rem 0 3rem 0;
}

.service__header {
    color: #E7F7fA;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
}

.service__header h2 {
    font-size: 3rem;
    font-weight: 400;
    margin: 0 auto;
    max-width: 95%;
    padding-bottom: .5rem;
}

.service__header p {
    font-size: 1.4rem;
    font-weight: 300;
    margin: 0 auto;
    max-width: 95%;
    padding: 2rem 0;
}

.services__container {
    align-items: center;
    border-top: 2px solid #00a7fb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 95%;
    padding-bottom: 2rem;
    padding-top: 1rem;
}

.services__container .card__section {
    display: flex;
    flex-direction: row;
    font-family: 'Outfit', sans-serif;
    text-align: center;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .services__container .card__section {
        flex-direction: column;
    }
}

@media screen and (max-width: 440px) {
    .service__header h2 {
        font-size: 2rem;
    }
}