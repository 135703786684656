.main-slide {
    max-width: 45%;
}

.carousel .slider-wrapper {
    width: 100%;
}

.carousel .thumb {
    width: 5%;
    height: 50px;
}

.carousel .thumb img {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.carousel {
    text-align: center;
}

@media screen and (max-width: 964px) {
    .main-slide {
        max-width: 70%;
    }
}

@media screen and (max-width: 569px) {
    .main-slide {
        max-width: 90%;
    }
}